<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  min-height: 100vh;
}
</style>

<template>
  <nav class="header-nav">
    <span class="logo" @click="handleToHome"></span
    ><span class="logo-name" @click="handleToHome">Yiwu Yichi Wire Mesh Company</span>
    <menu class="menu">
      <span class="menu-item" @click="handleClick">about us</span>
    </menu>
  </nav>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const handleToHome = () => {
  router.push("/index");
};

const handleClick = () => {
  router.push("/aboutUs");
};
</script>

<style lang="less" scoped>
.header-nav {
  display: flex;
  align-items: center;
  padding: 16px;
  .logo {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url("@/assets/img/logo.png") no-repeat center;
    background-size: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    margin-right: 8px;
  }
  .logo-name {
    font-weight: 500;
    font-size: 16px;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }

  .menu {
    color: #737373;
    font-size: 14px;
    flex: 1;
    margin-left: 40px;
    cursor: pointer;

    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
}
</style>

import { defineStore } from 'pinia'


const useMookDataStore = defineStore('mookData', {
    state: () => {
        return {
            products: []
        }
    }
})

export { useMookDataStore }

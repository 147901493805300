import './assets/common.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { ElCarousel, ElImage } from 'element-plus';
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'

const pinia = createPinia()
createApp(App)
    .use(ElCarousel)
    .use(ElImage)
    .use(pinia).use(router).mount('#app')
import { createWebHistory, createRouter } from 'vue-router';
import HomePage from '../view/homePage/homePage'
import AboutUs from '../view/aboutUs/aboutUs.vue'
import IndexPage from '../view/IndexPage/indexPage.vue';

const routes = [
    {
        path: '/',
        component: HomePage,
        redirect:'/index',
        children: [
            { path: '/index', component: IndexPage },
            { path: '/aboutUs', component: AboutUs },
        ]
    },
   
]

export default createRouter({
    history: createWebHistory(),
    routes,
})
<template>
  <div class="container">
    <div class="title">Factory Photo</div>
    <div class="image__placeholder">
      <div class="block" v-for="item of factory" :key="item">
        <el-image
          :preview-src-list="factory"
          :max-scale="7"
          :min-scale="0.2"
          :src="item"
        />
      </div>
    </div>
    <div class="title">Application Industry</div>
    <div class="image__placeholder">
      <div class="block" v-for="item of application" :key="item">
        <el-image
          :preview-src-list="factory"
          :max-scale="7"
          :min-scale="0.2"
          :src="item"
        />
      </div>
    </div>
    <div class="title">Logistics Shipping</div>
    <div class="image__placeholder">
      <div class="block" v-for="item of logistics" :key="item">
        <el-image
          :preview-src-list="factory"
          :max-scale="7"
          :min-scale="0.2"
          :src="item"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const factory = ref(
  Array.from({ length: 8 }, (_, index) => {
    return `/static/factory/f_${index + 1}.jpg`;
  })
);
const application = ref(
  Array.from({ length: 4 }, (_, index) => {
    return `/static/factory/y_${index + 1}.jpg`;
  })
);
const logistics = ref(
  Array.from({ length: 4 }, (_, index) => {
    return `/static/factory/fh_${index + 1}.jpg`;
  })
);
</script>

<style lang="less" scoped>
@media screen and (max-width: 1440px) {
  .block {
    width: 33%;
  }
}
@media (max-width: 768px) {
  .block {
    width: 100%;
  }
}
.container {
  margin: auto;
}

.image__placeholder .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
.image__placeholder .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
.image__placeholder .el-image {
  padding: 0 5px;
  max-width: 300px;
  max-height: 200px;
}

.image__placeholder.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 14px;
}
.image__placeholder .dot {
  animation: dot 2s infinite steps(3, start);
  overflow: hidden;
}
:deep(.el-image-viewer__img) {
  width: 80%;
}
</style>

/**不锈钢筛网 Stainless Steel Screen */
const stainlessSteelScreen = [
    {
        "id": 1,
        "name": "Stainless Steel Mesh",
        "nameCH": '不锈钢网',
        "desc": "Stainless steel mesh ranges from 1 mesh to 635 mesh. The main features of stainless steel mesh are accurate and stable material, flat and clean mesh surface without dirt, uniform mesh without deformation, good extension and wear resistance. Stainless steel screen is made of high-quality stainless steel wire under specific high-temperature process conditions, stretched to the specified wire diameter by a wire drawing machine, and then woven by a weaving machine in a constant temperature, dust-free and clean factory. Stainless steel screen weaving methods: plain weave, twill weave, dense weave, mat-type twill weave, double-wire Dutch weave, five-harness weave.Material: Stainless steel 302, 304, 304L, 310, 310S, 316, 316L, 321, etc.Specifications: Conventional plain weave 1-500 mesh, twill weave 250-500 mesh; special specifications can be customized;Roll width: conventional 0.914m-2.5m; maximum width can reach 6m;Roll length: 30m or 30.5m;Conventional weaving methods: plain, twill and dense weave.",
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelMesh/2.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelMesh/5.png',
        ]
    },
    {
        "id": 2,
        "name": "Stainless Steel Mat Mesh",
        "nameCH": '不锈钢席型网',
        "desc": "The mesh number of the mat mesh varies according to the different diameters of the warp and weft wires. Reasonable matching of wire diameter and mesh number can achieve the filtering accuracy that square mesh cannot achieve. Therefore, the mat mesh is a fine filtration dense mesh, but generally the diameter of the warp wire is larger than the diameter of the weft wire. The contrast mat mesh is called contrast because the weft wire is much thicker than the warp wire.The mat mesh is divided into: plain mat mesh, twill mat mesh, contrast mat mesh, high mesh mat mesh Mesh number: The mat mesh weave ranges from 7*40 mesh to 500*3500 mesh.Application and use:1. Used for screening and filtration under acid and alkaline environment conditions, as mud nets in the petroleum industry, as screens and filters in the chemical and chemical fiber industries, and electroplating./n 2. Used in mining, petroleum, chemical, food, medicine, machinery manufacturing and other industries.3. Used for screening and filtering under acidic and alkaline environment conditions, as mud net in petroleum industry, as screen in chemical fiber industry, as pickling net in electroplating industry. Our factory can design and manufacture various types of products according to user needs.",
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/2.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelMatMesh/5.png',
        ]
    },
    {
        "id": 3,
        "name": "Stainless Steel Welded Mesh",
        "nameCH": '不锈钢焊接网片',
        "desc": "--",
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelWeldedMesh/1.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldedMesh/2.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldedMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldedMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldedMesh/5.png',
            '/static/stainlessSteelScreen/StainlessSteelWeldedMesh/6.png',
        ]
    },
    {
        "id": 4,
        "name": "Stainless Steel Screen Embossing",
        "nameCH": '不锈钢筛网轧花',
        "desc": "--",
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/2.png',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/3.png',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/4.png',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/5.png',
            '/static/stainlessSteelScreen/StainlessSteelScreenEmbossing/6.png',
        ]
    },
    {
        "id": 5,
        "name": "Stainless Steel Wide Mesh",
        "nameCH": '不锈钢宽幅网',
        "desc": "--",
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelWideMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelWideMesh/2.png',
            '/static/stainlessSteelScreen/StainlessSteelWideMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelWideMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelWideMesh/5.png',
            '/static/stainlessSteelScreen/StainlessSteelWideMesh/6.png',
        ]
    },
    {
        "id": 6,
        "name": "Stainless Steel Gas And Liquid Filter",
        "nameCH": '不锈钢气液过滤网',
        "desc": "--",
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/2.jpg',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/3.png',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/4.png',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/5.png',
            '/static/stainlessSteelScreen/StainlessSteelGasAndLiquidFilter/6.png',
        ]
    },
    {
        "id": 7,
        "name": "Stainless Steel Perforated Mesh",
        "nameCH": '不锈钢冲孔网',
        "desc": "--",
        "picUrl": [
            '/static/stainlessSteelScreen/StainlessSteelPerforatedMesh/1.jpg',
            '/static/stainlessSteelScreen/StainlessSteelPerforatedMesh/2.jpg',
            '/static/stainlessSteelScreen/StainlessSteelPerforatedMesh/3.png',
            '/static/stainlessSteelScreen/StainlessSteelPerforatedMesh/4.png',
            '/static/stainlessSteelScreen/StainlessSteelPerforatedMesh/5.png',
            '/static/stainlessSteelScreen/StainlessSteelPerforatedMesh/6.png',
            '/static/stainlessSteelScreen/StainlessSteelPerforatedMesh/7.png',
            '/static/stainlessSteelScreen/StainlessSteelPerforatedMesh/8.png',
        ]
    },
    {
        "id": 8,
        "name": "--",
        "nameCH": '装饰网',
        "desc": "--",
        "picUrl": [
        ]
    },
    {
        "id": 9,
        "name": "Sintered Wire Mesh",
        "nameCH": '烧结网',
        "desc": "--",
        "picUrl": [
            '/static/stainlessSteelScreen/SinteredWireMesh/1.jpg',
            '/static/stainlessSteelScreen/SinteredWireMesh/2.jpg',
            '/static/stainlessSteelScreen/SinteredWireMesh/3.png',
            '/static/stainlessSteelScreen/SinteredWireMesh/4.png',
            '/static/stainlessSteelScreen/SinteredWireMesh/5.png',
            '/static/stainlessSteelScreen/SinteredWireMesh/6.png',
        ]
    }
]
/** 过滤网 Filter*/
const filter = [
    {
        "id": 1,
        "name": "Filter Mesh",
        "nameCH": '过滤网片',
        "desc": "--",
        "picUrl": [
            '/static/filter/FilterMesh/1.png',
            '/static/filter/FilterMesh/2.png',
            '/static/filter/FilterMesh/3.png',
            '/static/filter/FilterMesh/4.png',
            '/static/filter/FilterMesh/5.png',
            '/static/filter/FilterMesh/6.png',
            '/static/filter/FilterMesh/7.png',
            '/static/filter/FilterMesh/8.png',
        ]
    },
    {
        "id": 2,
        "name": "Filter Elements",
        "nameCH": '过滤滤芯',
        "desc": "--",
        "picUrl": [
            '/static/filter/FilterElements/1.jpg',
            '/static/filter/FilterElements/2.png',
            '/static/filter/FilterElements/3.png',
            '/static/filter/FilterElements/4.png',
            '/static/filter/FilterElements/5.png',
            '/static/filter/FilterElements/6.png',
            '/static/filter/FilterElements/7.png',
        ]
    },
    {
        "id": 3,
        "name": "Stainless Steel Filter Cartridge",
        "nameCH": '不锈钢过滤网筒',
        "desc": "--",
        "picUrl": [
            '/static/filter/StainlessSteelFilterCartridge/1.jpg',
            '/static/filter/StainlessSteelFilterCartridge/2.png',
            '/static/filter/StainlessSteelFilterCartridge/3.png',
            '/static/filter/StainlessSteelFilterCartridge/4.png',
            '/static/filter/StainlessSteelFilterCartridge/5.png',
            '/static/filter/StainlessSteelFilterCartridge/6.png',
            '/static/filter/StainlessSteelFilterCartridge/7.png',
            '/static/filter/StainlessSteelFilterCartridge/8.png',
            '/static/filter/StainlessSteelFilterCartridge/9.png',
            '/static/filter/StainlessSteelFilterCartridge/10.png',
            '/static/filter/StainlessSteelFilterCartridge/11.png',
            '/static/filter/StainlessSteelFilterCartridge/12.png',
            '/static/filter/StainlessSteelFilterCartridge/13.png',
        ]
    },
    {
        "id": 4,
        "name": "Stainless Steel Mesh Filter Cap",
        "nameCH": '不锈钢过滤网帽',
        "desc": "--",
        "picUrl": [
        ]
    },
    {
        "id": 5,
        "name": "Stainless Steel Wire Mesh Basket",
        "nameCH": '不锈钢过滤网篮',
        "desc": "--",
        "picUrl": [
        ]
    },
]
/**稀有金属网Rare Metal Mesh Net*/
const rareMetalMeshNet = [
    {
        "id": 1,
        "name": "Hastelloy Alloy Mesh",
        "nameCH": '哈氏合金网',
        "desc": "--",
        "picUrl": [
            '/static/rareMetalMeshNet/HastelloyAlloyMesh/1.jpg',
            '/static/rareMetalMeshNet/HastelloyAlloyMesh/2.jpg',
            '/static/rareMetalMeshNet/HastelloyAlloyMesh/3.png',
            '/static/rareMetalMeshNet/HastelloyAlloyMesh/4.png',
            '/static/rareMetalMeshNet/HastelloyAlloyMesh/5.png',
            '/static/rareMetalMeshNet/HastelloyAlloyMesh/6.png',
            '/static/rareMetalMeshNet/HastelloyAlloyMesh/7.png',
        ]
    },
    {
        "id": 2,
        "name": "Monel Mesh",
        "nameCH": '蒙乃尔网',
        "desc": "--",
        "picUrl": [
        ]
    },
    {
        "id": 3,
        "name": "Molybdenum Wire Mesh",
        "nameCH": '钼网',
        "desc": "--",
        "picUrl": [
        ]
    },
    {
        "id": 4,
        "name": "Nickel Wire Mesh",
        "nameCH": '镍网',
        "desc": "--",
        "picUrl": [
        ]
    },
    {
        "id": 5,
        "name": "Titanium Wire Mesh",
        "nameCH": '钛网',
        "desc": "--",
        "picUrl": [
        ]
    },
    {
        "id": 6,
        "name": "Copper Mesh",
        "nameCH": '铜网',
        "desc": "--",
        "picUrl": [
        ]
    },
    {
        "id": 7,
        "name": "Tungsten Wire Mesh",
        "nameCH": '钨丝网',
        "desc": "--",
        "picUrl": [
        ]
    },
    {
        "id": 8,
        "name": "Silver Wire Mesh",
        "nameCH": '银网',
        "desc": "--",
        "picUrl": [
        ]
    },

]
const companyDesc = 'We have been specialized in producing different types of woven wire mesh and further processed products for 20 years. Main products: Metal wire mesh: mat type mesh, welded mesh, special material wire mesh: copper mesh, duplex steel mesh, nickel mesh, titanium mesh, molybdenum mesh, tungsten mesh, silver mesh, iron chromium aluminum mesh, Hastelloy mesh, monel wire mesh. Further processing: filter mesh, filter mesh cylinder, metal filter element, stainless steel mesh basket, gas and liquid filter metal decorative mesh. With the excellent quality of high temperature resistance, corrosion resistance, acid and alkali resistance, and wear resistance, we have won deep praise from domestic and foreign customers./n We sincerely hope to provide high-quality products, preferential prices and timely delivery to every customer around the world. Welcome to contact us for a quote on wire mesh products. Looking forward to meeting you.'
export {
    stainlessSteelScreen,
    filter,
    rareMetalMeshNet,
    companyDesc
}
<template>
  <headerNav></headerNav>
  <main class="body-container">
    <router-view />
  </main>
  <footer></footer>
</template>

<script setup>
import { onMounted } from "vue";
import headerNav from "@/components/headerNav.vue";
// import request from "@/utils/request.js";
import { useMookDataStore } from "@/store/pinia";
import { products } from "@/mock/products";

const store = useMookDataStore();
// if (process.env.NODE_ENV === "development") {
//   request.get(process.env.VUE_APP_EDGE_CONFIG).then((res) => {
//     store.products = res.data.items.products;
//   });
// }

onMounted(async () => {
  // if (process.env.NODE_ENV !== "development") {
  //   request.get("/api/get-edge-config?key=products").then((res) => {
  //     store.products = res.data.value;
  //   });
  // }
  store.products = products;
});
</script>

<style lang="less" scoped>
.body-container {
  max-width: 1440px;
  margin: auto;
  padding: 16px;
}
</style>
